import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const FILE_SIZE_VALIDATOR_NAME = 'fileSize';

export const validateFileSize = (maxSize: number): ValidatorFn => (control: AbstractControl): ValidationErrors | null => {
  if (control.value?.size > maxSize) {
    return { [FILE_SIZE_VALIDATOR_NAME]: true, maxSize };
  }

  return null;
};
