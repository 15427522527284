export class Notification {
  public static toast() { // TODO: PERSISTENT flag for longer visibility
    return new NotificationFactory<ToastPayload>(NotificationAppearance.TOAST);
  }

  public static inline() {
    return new NotificationFactory<InlineBoxPayload>(NotificationAppearance.INLINE);
  }
}

class NotificationFactory<TPayload = ToastPayload | InlineBoxPayload> {
  public constructor(
    private appearance: NotificationAppearance
  ) { }

  public asError(payload: TPayload) {
    return this.build(NotificationType.ERROR, payload);
  }

  public asSuccess(payload: TPayload) {
    return this.build(NotificationType.SUCCESS, payload);
  }

  public asWarning(payload: TPayload) {
    return this.build(NotificationType.WARNING, payload);
  }

  public asInfo(payload: TPayload) {
    return this.build(NotificationType.INFO, payload);
  }

  private build(type: NotificationType, payload: unknown) {
    switch (this.appearance) {
      case NotificationAppearance.TOAST: {
        return new ToastNotification(type, payload as ToastPayload);
      }
      case NotificationAppearance.INLINE: {
        return new InlineNotification(type, payload as InlineBoxPayload);
      }
    }
  }
}

abstract class BaseNotification {
  protected constructor(
    public type: NotificationType,
    public messageKey: string,
    public message: string,
    public messageParams: { [key: string]: string },
    public liveTime: NotificationLiveTime
  ) { }
}

export class ToastNotification extends BaseNotification {
  public constructor(type: NotificationType, { messageKey, message, messageParams, liveTime }: ToastPayload) {
    super(type, messageKey, message, messageParams, liveTime);
  }
}

export class InlineNotification extends BaseNotification {
  public showLink?: boolean;
  public linkUrl?: string;
  public linkText?: string;
  public identifier?: string;

  public constructor(
    type: NotificationType,
    { messageKey, message, messageParams, showLink, linkUrl, linkText, liveTime, placeholderId }: InlineBoxPayload
  ) {
    super(type, messageKey, message, messageParams, liveTime);

    this.showLink = showLink;
    this.linkUrl = linkUrl;
    this.linkText = linkText;
    this.identifier = placeholderId;
  }
}

export class ClearInlineNotifications {
  public constructor(public identifier?: string) { }
}

export type SupportedNotification = ToastNotification | InlineNotification | ClearInlineNotifications;

interface BasePayload {
  messageKey: string;
  message?: string;
  messageParams?: { [key: string]: string };
  liveTime?: NotificationLiveTime;
}

export type ToastPayload = BasePayload;

export interface InlineBoxPayload extends BasePayload {
  showLink?: boolean;
  linkUrl?: string;
  linkText?: string;
  placeholderId?: string;
}

export enum NotificationLiveTime {
  NORMAL,
  LONG,
  UNTIL_TAPPED
}

export enum NotificationAppearance {
  TOAST,
  INLINE
}

export enum NotificationType {
  SUCCESS,
  ERROR,
  WARNING,
  INFO
}
