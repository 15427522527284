import { FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';
import { DateFormats } from '@shared/common';

export const DATE_RANGE_VALIDATOR_NAME = 'validDateRange';

// eslint-disable-next-line max-len
export const validateDateRange = (firstControlName: string, secondControlName: string): ValidatorFn => (formGroup: FormGroup): ValidationErrors | null => {
  const value = formGroup.value;

  if (!value[firstControlName] || !value[secondControlName]) { return null; }

  const moveIn = !value[firstControlName]
    ? null
    : moment(value[firstControlName], DateFormats.date, true);
  const moveOut = !value[secondControlName]
    ? null
    : moment(value[secondControlName], DateFormats.date, true);

  if (!moveIn.isValid() || !moveOut.isValid()) { return null; }

  if (!moveIn.isAfter(moveOut)) { return null; }

  return { [DATE_RANGE_VALIDATOR_NAME]: true };
};
