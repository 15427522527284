import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';
import { DateFormats } from '@shared/common';

export const DATE_VALIDATOR_NAME = 'validDate';

export const validateDate = (): ValidatorFn => (control: AbstractControl): ValidationErrors | null => {
  if (!control.value) { return null; }

  const isValid = moment(control.value, DateFormats.date, true).isValid();

  return isValid
    ? null
    : { [DATE_VALIDATOR_NAME]: true };
};
