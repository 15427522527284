import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PublicPagesService {
  private publicPages = ['/imprint', '/terms', '/contact', '/logout', '/register'];

  public constructor(
    private router: Router,
  ) { }

  public userIsOnPublicPage(url = this.router.routerState.snapshot.url) {
    return this.publicPages.includes(url);
  }
}
