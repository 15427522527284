import { Injectable } from '@angular/core';
import { AppError } from '../models/app-error.model';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppErrorService {
  public error$: Observable<AppError>;

  private errorBus = new BehaviorSubject<AppError>(undefined);

  public constructor() {
    this.subscribeToError();
  }

  public raise(error: AppError) {
    this.errorBus.next(error);
  }

  private subscribeToError() {
    this.error$ = this.errorBus.asObservable();
  }
}
