import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environment';
import { ChangePasswordPayload } from '../models/change-password-payload.model';

@Injectable({
  providedIn: 'root'
})
export class PasswordApiService {

  public constructor(
    private http: HttpClient
  ) { }

  public changePassword(userId: number, payload: ChangePasswordPayload) {
    return this.http.put(
      `${environment.restEndpoint}/user/${userId}/password`,
      payload
    );

  }
}
