import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PasswordStrengthService {

  public validate(password: string) {
    return this.checkIf(password).meetsAllConditions(
      this.hasProperLength,
      this.atLeastOneUpperAndLowercaseLetter,
      this.atLeastOneNumber,
      this.atLeastOneSpecialChar
    );
  }

  private checkIf(password: string) {
    return {
      meetsAllConditions: (...delegates: ((value: string) => boolean)[]) =>
        delegates.every(
          delegate => delegate(password)
        )
    };
  }

  private atLeastOneUpperAndLowercaseLetter(password: string) {
    return /(?=.*[a-z])(?=.*[A-Z])/.test(password);
  }

  private atLeastOneNumber(password: string) {
    return /[0-9]+/.test(password);
  }

  private atLeastOneSpecialChar(password: string) {
    return /[*@!#%&()^~{}]+/.test(password);
  }

  private hasProperLength(password: string) {
    const minLength = 12;
    const maxLength = 64;

    return minLength <= password.length && password.length <= maxLength;
  }
}
