import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { delay, finalize, Observable } from 'rxjs';
import { SpinnerService } from './spinner.service';

@Injectable()
export class AttachSpinnerToMatchingRequestInterceptor implements HttpInterceptor {

  public constructor(
    private spinner: SpinnerService
  ) { }

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // TODO: bypass condition

    // const requestingForToken = request.url.endsWith('/token');
    // const requestingForTranslation = request.url.endsWith('.json');
    const requestingForSvg = request.url.endsWith('.svg');
    const bypassSpinner = requestingForSvg; // requestingForToken || requestingForTranslation;

    if (!bypassSpinner) {
      this.spinner.push();
    }

    return next.handle(request)
      .pipe(
        delay(350),
        finalize(() => {
          if (bypassSpinner) { return; }

          this.spinner.pop();
        })
      );
  }
}
