import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NumberFormatService {
  private DETAILS = {
    'pl-pl': { CURRENCY_SYM: 'zł', DECIMAL_SEP: ',', GROUP_SEP: ' ' },
    'de-de': { CURRENCY_SYM: '€', DECIMAL_SEP: ',', GROUP_SEP: '.' },
    'it-it': { CURRENCY_SYM: '€', DECIMAL_SEP: ',', GROUP_SEP: '.' },
    'ro-ro': { CURRENCY_SYM: 'RON', DECIMAL_SEP: ',', GROUP_SEP: '.' },
    'en-gb': { CURRENCY_SYM: '£', DECIMAL_SEP: '.', GROUP_SEP: ',' },
    'cs-cz': { CURRENCY_SYM: 'Kč', DECIMAL_SEP: ',', GROUP_SEP: ' ' }
  };

  public getFormattingDetails(locale: string) {
    return this.DETAILS[locale];
  }

  public parseNumberByLocale(value: string, locale: string) {
    const format = this.getFormattingDetails(locale);
    const normalizedValue = (value || '')
      .replace(format.GROUP_SEP, '')
      .replace(format.DECIMAL_SEP, '.');

    return Number.parseFloat(normalizedValue);
  }
}
