export const CurrencySymbols = {
  USD: '$',
  EUR: '€',
  PLN: 'zł',
  GBP: '£',
  RON: 'lei',

  ALL: 'Lek',
  DZD: 'د.ج',
  AOA: 'Kz',
  XCD: '$',
  ARS: '$',
  AWG: 'ƒ',
  AUD: '$',
  AZN: 'ман',
  BSD: '$',
  BHD: 'BD',
  BDT: '৳',
  BBD: '$',
  BYR: 'p.',
  BZD: 'BZ$',
  XOF: 'CFA',
  BMD: '$',
  BTN: 'Nu',
  INR: '₹',
  BOB: '$b',
  BOV: '$b',
  BAM: 'KM',
  BWP: 'P',
  NOK: 'kr',
  BRL: 'R$',
  BND: '$',
  BGN: 'лв',
  BIF: 'BIF',
  KHR: '៛',
  CAD: '$',
  CVE: '$',
  KYD: '$',
  CLP: '$',
  CNY: '¥',
  COP: '$',
  NZD: '$',
  CRC: '₡',
  HRK: 'kn',
  CUP: '₱',
  ANG: 'ƒ',
  CZK: 'Kč',
  DKK: 'kr',
  DOP: 'RD$',
  EGP: '£',
  SVC: '$',
  FKP: '£',
  FJD: '$',
  GIP: '£',
  GTQ: 'Q',
  GYD: '$',
  HNL: 'L',
  HKD: '$',
  HUF: 'Ft',
  ISK: 'kr',
  IDR: 'Rp',
  ILS: '₪',
  JMD: 'J$',
  JPY: '¥',
  KZT: '₸',
  KPW: '₩',
  KRW: '₩',
  KGS: 'som',
  LAK: '₭',
  LBP: '£',
  ZAR: 'R',
  LRD: '$',
  CHF: 'CHF',
  LTL: 'Lt',
  MKD: 'ден',
  MYR: 'RM',
  MUR: '₨',
  MXN: '$',
  MNT: '₮',
  MZN: 'MT',
  NAD: '$',
  NPR: '₨',
  NIO: 'C$',
  NGN: '₦',
  PKR: '₨',
  PAB: 'B/.',
  PGK: 'K',
  PYG: 'Gs',
  PEN: 'S/',
  PHP: '₱',
  RUB: 'руб',
  RWF: 'R₣',
  SHP: '£',
  WST: '$',
  RSD: 'Дин.',
  SCR: '₨',
  SLL: 'Le',
  SGD: '$',
  SBD: '$',
  SOS: 'S',
  LKR: '₨',
  SRD: '$',
  SEK: 'kr',
  SYP: '£',
  TWD: 'NT$',
  THB: '฿',
  TTD: 'TT$',
  TRY: '₺',
  UAH: '₴',
  UYU: '$U',
  UZS: 'som',
  VEF: 'Bs',
  VND: '₫',
  ZWL: '$',
};
