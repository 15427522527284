import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class TranslationPrefix {
  private prefix: string;

  public apply(prefix: string) {
    this.prefix = prefix;
  }

  public include(key: string) {
    return !this.prefix
      ? key
      : `${this.prefix}.${key}`;
  }
}
