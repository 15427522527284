import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ColorService {

  public blendColors(rgb1: number[], rgb2: number[], factor: number) {
    return rgb1.reduce((result, currentColor, idx) => [
      ...result,
      Math.round(currentColor + (factor * (rgb2[idx] - currentColor))),
    ], []);
  }

  public hexToRgb(hexColorCode: string) {
    const individualValues = hexColorCode?.replace('#', '').match(/.{1,2}/g);

    if (individualValues?.length !== 3) {
      throw new Error('Provided value is not in hex format');
    }

    return [
      parseInt(individualValues[0], 16),
      parseInt(individualValues[1], 16),
      parseInt(individualValues[2], 16),
    ];
  }

  public rgbToHex(rgbValues: number[]) {
    return `#${rgbValues.map(c => c.toString(16).padStart(2, '0')).join('')}`;
  }

}
