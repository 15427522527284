export enum TimeUnit {
  MONTH = 'MONTH',
  DAY = 'DAY',
};

export const DateFormats = {
  year: 'YYYY',
  yearMonth: 'YYYY-MM',
  date: 'DD-MM-YYYY',
  dateReverseDayMonth: 'MM-DD-YYYY',
  dateReverse: 'YYYY-MM-DD',
  dateLong: 'D MMM YYYY',
  monthName: 'MMMM',
  monthNameWithYear: 'MMMM YYYY',
  monthNameShort: 'MMM',
  monthNameShortWithYear: 'MMM YYYY',
  weekNameShort: 'EEEEEE',
  dayOfMonth: 'MMM D',
  dateWithTime: 'DD-MM-yyyy HH:mm:ss'
};

export interface DateRange<TDate> {
  startDate: TDate;
  endDate: TDate;
}
